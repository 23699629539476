<template>
  <b-card title="">
    <h3 class="text-primary">Firewalls</h3>
    <!-- {{ firewallTable }} -->
    <b-row>
      <b-col md="6" class="mb-1"> </b-col>
      <b-col md="5" class="mb-1">
        <!-- <b-form-group
          label-cols-sm="5"
          label-align-sm="right"
          label-size="sm"
          label-for="filterInput"
          class="mb-0"
        >
          <b-input-group size="sm">
            <b-form-input
              id="filterInput"
              v-model="filter"
              type="search"
              placeholder="Search"
              style="padding: 18px 9px"
            />
          </b-input-group>
        </b-form-group> -->
      </b-col>
      <b-col md="1" class="mb-1">
        <b-form-group>
          <b-button
            v-if="
              $store.state.app.user.permissions.includes(
                'firewall.add_firewall'
              )
            "
            class="btn btn-success"
            @click="gotoAddFirewall()"
            size="sm"
          >
            Add +
          </b-button>
        </b-form-group>
      </b-col>

      <b-col cols="12">
        <b-table
          bordered
          hover
          responsive
          :per-page="perPage"
          :current-page="currentPage"
          :items="firewallTable"
          :fields="fields"
          :sort-by.sync="sortBy"
          :sort-desc.sync="sortDesc"
          :sort-direction="sortDirection"
          :filter="filter"
          :filter-included-fields="filterOn"
          @filtered="onFiltered"
        >
          <template #cell(#)="data">
            {{ data.index + 1 }}
          </template>

          <template #cell(status)="data">
            <b-badge :variant="status[1][data.value]">
              {{ status[0][data.value] }}
            </b-badge>
          </template>
          <template #cell(cloud)="data">
            <b-badge
              variant="primary"
              v-for="(key, value) in data.item.cloud_profile_data"
              :key="value"
            >
              <span v-if="value == 'name'">{{ key }}</span>
            </b-badge>
          </template>
          <template #cell(application)="data">
            <ul class="pl-0" style="list-style: none">
              <li v-for="(item, i) in data.item.applications" :key="i">
                <b-badge variant="success">
                  {{ item.application_name }}
                </b-badge>
              </li>
            </ul>
          </template>
          <template #cell(action)="data">
            <div style="display: flex">
              <b-button
                v-b-tooltip.hover
                title="Revoke"
                v-if="
                  $store.state.app.user.permissions.includes(
                    'firewall.change_firewall'
                  )
                "
                v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                variant="secondary"
                class="btn-icon rounded-circle"
                @click="showModalRevoke(data.item.id)"
              >
                <feather-icon icon="RotateCcwIcon" />
              </b-button>
              <b-button
                v-if="
                  $store.state.app.user.permissions.includes(
                    'firewall.change_firewall'
                  )
                "
                v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                variant="warning"
                class="ml-1 btn-icon rounded-circle"
                @click="gotoEditFirewall(data.item.id)"
              >
                <feather-icon icon="EditIcon" />
              </b-button>
              <b-button
                v-if="
                  $store.state.app.user.permissions.includes(
                    'firewall.delete_firewall'
                  )
                "
                variant="gradient-danger"
                class="ml-1 btn-icon rounded-circle"
                @click="showModal(data.item.id)"
              >
                <feather-icon icon="TrashIcon" />
              </b-button>
            </div>
          </template>
        </b-table>
      </b-col>

      <b-col cols="12">
        <b-row>
          <b-col md="2" sm="4">
            <b-form-group class="mb-0">
              <b-form-select
                id="perPageSelect"
                v-model="perPage"
                size="sm"
                :options="pageOptions"
                class="w-50"
              />
            </b-form-group>
          </b-col>
          <b-col md="10" sm="4">
            <b-pagination
              v-model="currentPage"
              :total-rows="rows"
              :per-page="perPage"
              align="right"
              size="sm"
              class="my-0"
            />
          </b-col>
        </b-row>
      </b-col>
    </b-row>
    <b-modal
      id="modal-danger"
      ref="modal-danger"
      ok-only
      ok-variant="danger"
      @ok="deleteFirewall(fireid)"
      modal-class="modal-danger"
      centered
      title="Delete !"
    >
      <b-card-text>
        <!-- {{this.fireid}} -->
        Are You Sure to Delete the Firewall ?
      </b-card-text>
    </b-modal>
    <b-modal
      id="revoke-modal-dangerr"
      ref="revoke-modal-danger"
      ok-only
      ok-variant="danger"
      @ok="revokeFirewall(fireid)"
      modal-class="modal-danger"
      centered
      title="Revoke !"
    >
      <b-card-text>
        <!-- {{this.fireid}} -->
        The revoke action will clear all the inbound rules of the firewall. This action is not reversible. Are you
        sure you want to continue?
      </b-card-text>
    </b-modal>
  </b-card>
</template>

<script>
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import Ripple from "vue-ripple-directive";
import {
  BCard,
  BCardText,
  BTable,
  BAvatar,
  BBadge,
  BButton,
  BRow,
  BCol,
  BFormGroup,
  BFormSelect,
  BPagination,
  BInputGroup,
  BFormInput,
  VBModal,
} from "bootstrap-vue";

export default {
  components: {
    BCard,
    BCardText,
    BTable,
    BAvatar,
    BBadge,
    BButton,
    BRow,
    BCol,
    BFormGroup,
    BFormSelect,
    BPagination,
    BInputGroup,
    BFormInput,
    VBModal,
  },
  directives: {
    "b-modal": VBModal,
    Ripple,
  },
  data() {
    return {
      firewallTable: [],
      perPage: 5,
      pageOptions: [3, 5, 10],
      totalRows: 1,
      currentPage: 1,
      sortBy: "",
      sortDesc: false,
      sortDirection: "asc",
      filter: null,
      filterOn: [],
      fields: [
        "#",
        {
          key: "name",
          label: "Firewall Name",
          sortable: true,
        },
        {
          key: "firewall_id",
          label: "Firewall ID",
        },
        {
          key: "region",
          label: "Region",
        },
        {
          key: "cloud",
          label: "Cloud Profile",
        },
        {
          key: "application",
          label: "Application",
        },
        { key: "action", label: "Action", sortable: true },
      ],
      items: [],
      actions: [
        {
          1: "primary",
          2: "danger",
        },
      ],
      fireid: "",
    };
  },
  computed: {
    sortOptions() {
      // Create an options list from our fields
      return this.fields
        .filter((f) => f.sortable)
        .map((f) => ({ text: f.label, value: f.key }));
    },
    rows() {
      return this.firewallTable.length;
    },
  },
  mounted() {
    this.totalRows = this.items.length;
    this.getFirewallList();
  },
  created() {
    // (this.tenantid = this.$store.getters.tenantid),
    //   (this.accesstoken = this.$store.getters.token);
    //   console.log(this.tenantid);
  },
  methods: {
    showModal(itemid) {
      this.$refs["modal-danger"].show();
      this.fireid = itemid;
    },
    showModalRevoke(itemid) {
      this.$refs["revoke-modal-danger"].show();
      this.fireid = itemid;
    },

    info(item, index, button) {
      this.infoModal.title = `Row index: ${index}`;
      this.infoModal.content = JSON.stringify(item, null, 2);
      this.$root.$emit("bv::show::modal", this.infoModal.id, button);
    },
    resetInfoModal() {
      this.infoModal.title = "";
      this.infoModal.content = "";
    },
    gotoAddFirewall() {
      this.$router.push("/eca-createfirewall");
    },
    onFiltered(filteredItems) {
      // Trigger pagination to update the number of buttons/pages due to filtering
      this.totalRows = filteredItems.length;
      this.currentPage = 1;
    },
    getFirewallList: function () {
      const options = {
        method: "GET",
        // headers: {
        //   Authorization: `Bearer ${localStorage.getItem("JWTtoken")}`,
        //   "content-type": "application/json",
        // },
        url: process.env.VUE_APP_BASEURL + `firewall/firewall/`,
      };
      var self = this;
      this.$http(options)
        .then((res) => {
          console.log(res.data);
          this.firewallTable = res.data;
        })
        .catch((err) => {
          console.log(err);
        });
    },
    gotoEditFirewall(id) {
      this.$router.push(`/eca-editfirewall/${id}`);
    },

    deleteFirewall(fireid) {
      const data = {
        id: fireid,
      };
      const options = {
        method: "DELETE",
        data: data,
        headers: { "content-type": "application/json" },
        url: process.env.VUE_APP_BASEURL + `firewall/firewall/${fireid}/`,
      };
      this.$http(options)
        .then((res) => {
          this.getFirewallList();
          console.log(res.data);
        })
        .catch((err) => {
          console.log(err);
        });
    },

    revokeFirewall(fireid) {
      let data = {
        id: this.fireid,
      };
      const options = {
        method: "POST",
        data: data,
        headers: { "content-type": "application/json" },
        url:
          process.env.VUE_APP_BASEURL +
          `firewall/firewall/${fireid}/revoke_firewall_rules/`,
      };
      var self = this;
      this.$http(options).then((res) => {
        console.log(res.data, " revoke");
        if (res.data.status_code === 204) {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: res.data.message,
              icon: "EditIcon",
              variant: "success",
            },
          });
          this.getFirewallList();
        } else {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: res.data.message,
              icon: "EditIcon",
              variant: "danger",
            },
          });
        }
      });
    },
  },
};
</script>

<style>
[dir] .table:not(.table-dark):not(.table-light) thead:not(.thead-dark) th,
[dir] .table:not(.table-dark):not(.table-light) tfoot:not(.thead-dark) th {
  background-color: #7367f0 !important;
  color: #fff !important;
}
[dir] .table th,
[dir] .table td {
  padding: 0.72rem 2rem;
  text-align: center;
}
</style>
